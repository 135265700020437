// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyfEwOS-SZ-w2uWCJ-XTQlvTmjFgo-ofw",
  authDomain: "lawyeredupwebsite.firebaseapp.com",
  projectId: "lawyeredupwebsite",
  storageBucket: "lawyeredupwebsite.appspot.com",
  messagingSenderId: "102397937360",
  appId: "1:102397937360:web:dd0f6ad1caeca00def40d5",
  measurementId: "G-DJE6BCPG93"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 const projectFunctions = getFunctions(app);

export { app, analytics, projectFunctions };