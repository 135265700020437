<template>
  <div>
    <!-- Image Section -->
    <div class="relative mt-44 md:mt-32 flex justify-center items-center w-full">
      <img
        :src="images.countries"
        alt="Big Screen"
        class="hidden md:block w-3/4"
      />
      <img
        :src="images.countriesSmall"
        alt="Small Screen"
        class="block md:hidden"
      />
    </div>

    <!-- Text and Button Section -->
    <div
      class="relative flex flex-col md:flex-row items-center justify-between w-full my-36 z-20"
    >
      <div
        class="relative w-full md:w-[55%] flex flex-col items-start justify-center space-y-8 pl-12"
      >
        <Button
          buttonText="All-in-one legal tool"
          backgroundColor="bg-amber-100"
          textColor="text-[#FDAD1C]"
          borderColor="border-amber-100"
          class="bg-opacity-70 cursor-not-allowed"
        />
        <h1
          ref="typedElement"
          class="text-4xl md:text-[55px] font-semibold text-primary leading-[45px] lg:leading-[75px]"
        ></h1>
        <p class="text-sm md:text-base lg:text-lg text-primary">
          Create, sign, store, manage, and book a consultation.
        </p>
        <div class="flex space-x-4">
          <a
            href="https://linktr.ee/lawyeredup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              buttonText="Request a demo"
              backgroundColor="bg-primary"
              borderColor="bg-primary"
            />
          </a>
          <router-link to="/contact-us">
            <Button
              buttonText="Contact us"
              backgroundColor="bg-white"
              textColor="text-primary"
              borderColor="border-primary"
            />
          </router-link>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-full lg:w-[42%] mt-8 md:mt-0 flex justify-center p-0 m-0 z-10">
        <img :src="images.screen" alt="Big Screen" class="lg:w-full md:w-full " />
      </div>
    </div>
  </div>
</template>

<style scoped>
.relative.flex.justify-center.items-center.w-full {
  position: absolute;
  top: -50px;
  z-index: 10; 
}

.relative.flex.flex-col.md\:flex-row.items-center.justify-between.w-full.my-36 {
  position: relative;
  z-index: 20; 
}
</style>


<script>
import { onMounted, ref } from "vue";
import Typed from "typed.js";
import { Icon } from "@iconify/vue";
import Button from "../UI/Button.vue";
import { images } from "@/data/images";

export default {
  components: { Icon, Button },
  setup() {
    const typedElement = ref(null);

    onMounted(() => {
      const options = {
        strings: [
          "Simplifying Legal Processes for SMEs with AI-Powered Solutions",
        ],
        typeSpeed: 50,
        backSpeed: 0,
        showCursor: false,
        loop: true,
      };

      new Typed(typedElement.value, options);
    });

    return {
      typedElement,
      images,
    };
  },
};
</script>

<style scoped>
</style>